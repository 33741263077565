import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

import './TransposeDropdown.css';

const languageOptions = [
  { key: 'Arabic', text: 'Arabic', value: 'Arabic' },
  { key: 'Chinese', text: 'Chinese', value: 'Chinese' },
  { key: 'Danish', text: 'Danish', value: 'Danish' },
  { key: 'Dutch', text: 'Dutch', value: 'Dutch' },
  { key: 'English', text: 'English', value: 'English' },
  { key: 'French', text: 'French', value: 'French' },
  { key: 'German', text: 'German', value: 'German' },
  { key: 'Greek', text: 'Greek', value: 'Greek' },
  { key: 'Hungarian', text: 'Hungarian', value: 'Hungarian' },
  { key: 'Italian', text: 'Italian', value: 'Italian' },
  { key: 'Japanese', text: 'Japanese', value: 'Japanese' },
  { key: 'Korean', text: 'Korean', value: 'Korean' },
  { key: 'Lithuanian', text: 'Lithuanian', value: 'Lithuanian' },
  { key: 'Persian', text: 'Persian', value: 'Persian' },
  { key: 'Polish', text: 'Polish', value: 'Polish' },
  { key: 'Portuguese', text: 'Portuguese', value: 'Portuguese' },
  { key: 'Russian', text: 'Russian', value: 'Russian' },
  { key: 'Spanish', text: 'Spanish', value: 'Spanish' },
  { key: 'Swedish', text: 'Swedish', value: 'Swedish' },
  { key: 'Turkish', text: 'Turkish', value: 'Turkish' },
  { key: 'Vietnamese', text: 'Vietnamese', value: 'Vietnamese' },
]

// const keyOptions = [
//   { text: 'A',  value: 0 },
//   { text: 'A#', value: 1 },
//   { text: 'B',  value: 2 },
//   { text: 'C',  value: 3 },
//   { text: 'C#', value: 4 },
//   { text: 'D',  value: 5 },
//   { text: 'D#', value: 6 },
//   { text: 'E',  value: 7 },
//   { text: 'F',  value: 8 },
//   { text: 'F#', value: 9 },
//   { text: 'G',  value: 10 },
//   { text: 'G#', value: 11 },
// ]

const keyOptions = [
  { text: 'A',  value: 'A' },
  { text: 'A#', value: 'A#'},
  { text: 'B',  value: 'B' },
  { text: 'C',  value: 'C' },
  { text: 'C#', value: 'C#'},
  { text: 'D',  value: 'D' },
  { text: 'D#', value: 'D#'},
  { text: 'E',  value: 'E' },
  { text: 'F',  value: 'F' },
  { text: 'F#', value: 'F#'},
  { text: 'G',  value: 'G' },
  { text: 'G#', value: 'G#'},
]

const keyOptionsMinor = [
  { text: 'Am',  value: 'Am'  },
  { text: 'A#m', value: 'A#m' },
  { text: 'Bm',  value: 'Bm'  },
  { text: 'Cm',  value: 'Cm'  },
  { text: 'C#m', value: 'C#m' },
  { text: 'Dm',  value: 'Dm'  },
  { text: 'D#m', value: 'D#m' },
  { text: 'Em',  value: 'Em'  },
  { text: 'Fm',  value: 'Fm'  },
  { text: 'F#m', value: 'F#m' },
  { text: 'Gm',  value: 'Gm'  },
  { text: 'G#m', value: 'G#m' },
]

const capoOptions = [
  { text: '0',  value: 0 },
  { text: '1',  value: 1 },
  { text: '2',  value: 2 },
  { text: '3',  value: 3 },
  { text: '4',  value: 4 },
  { text: '5',  value: 5 },
  { text: '6',  value: 6 },
  { text: '7',  value: 7 },
  { text: '8',  value: 8 },
  { text: '9',  value: 9 },
  { text: '10', value: 10 },
  { text: '11', value: 11 },
  { text: '12', value: 12 },
]

class Pitch {
  // constructor(pitchStr) {
  //   this.pitchStr = pitchStr
  // }

  static PitchToNum = {
    'A' : 0,
    'A#': 1,
    'Bb': 1,
    'B' : 2,
    'C' : 3,
    'C#': 4,
    'Db': 4,
    'D' : 5,
    'D#': 6,
    'Eb': 6,
    'E' : 7,
    'F' : 8,
    'F#': 9,
    'Gb': 9,
    'G' : 10,
    'G#': 11,
    'Ab': 11,
  }

  static NumToPitchSharp = [
    'A' ,
    'A#',
    'B' ,
    'C' ,
    'C#',
    'D' ,
    'D#',
    'E' ,
    'F' ,
    'F#',
    'G' ,
    'G#',
  ]

  static NumToPitchFlat = [
    'A' ,
    'Bb',
    'B' ,
    'C' ,
    'Db',
    'D' ,
    'Eb',
    'E' ,
    'F' ,
    'Gb',
    'G' ,
    'Ab',
  ]

  static isSharp(pitchStr) {
    return pitchStr.includes('#')
  }

  static transpose(pitchStr, semitones) {
    console.log(`pitch from Pitch.transpose: ${pitchStr}`);
    console.log(`pitchNum: ${this.PitchToNum[pitchStr]}, semitones: ${semitones}`);
    var newPitchNum = (this.PitchToNum[pitchStr] + semitones) % 12;
    console.log(`new pitchNum: ${newPitchNum}`);
    console.log(`new pitch from Pitch.transpose: ${this.NumToPitchSharp[newPitchNum]}`);
    return this.NumToPitchSharp[newPitchNum];
  }

  static semitoneDifference(pitch1, pitch2) {
    var diff = (this.PitchToNum[pitch2] - this.PitchToNum[pitch1]) % 12;
    return (diff < 0) ? diff + 12 : diff;
  }
}

class Chord {
  static transpose(chord, semitones) {
    var mainChord = this.isSlash(chord) ? this.getSlashTop(chord) : chord;

    var startOfQualityIdx = (mainChord.includes('#') || mainChord.includes('b')) ? 2 : 1;
    var pitch = mainChord.slice(0, startOfQualityIdx);
    var newPitch = Pitch.transpose(pitch, semitones);
    var newChord = newPitch + mainChord.slice(startOfQualityIdx);
    if(this.isSlash(chord)) {
      newChord += '/' + Pitch.transpose(this.getSlashBottom(chord), semitones);
    }

    return newChord;
  }

  static semitoneDifference(chord1, chord2) {
    var startOfQualityIdx1 = (chord1.includes('#') || chord1.includes('b')) ? 2 : 1;
    var startOfQualityIdx2 = (chord2.includes('#') || chord2.includes('b')) ? 2 : 1;

    var pitch1 = chord1.slice(0, startOfQualityIdx1);
    var pitch2 = chord2.slice(0, startOfQualityIdx2);

    return Pitch.semitoneDifference(pitch1, pitch2);
  }

  static getPitch(chord) {
    // if (chord.length > 0 && ['I', 'i', 'V', 'v'].includes(chord[0])) {
    //   return this.getRomanPitch(chord);
    // }
    var startOfQualityIdx = (chord.includes('#') || chord.includes('b')) ? 2 : 1;
    return chord.slice(0, startOfQualityIdx);
  }

  static getRomanPitch(chord) {
    var pitch = "";
    for (var i = 0; i < chord.length; i++) {
      if (['I', 'i', 'V', 'v'].includes(chord[i])) {
        pitch += chord[i];
      }
    }
    return pitch;
  }

  static getQuality(chord) {
    if (chord.length > 0 && ['I', 'i', 'V', 'v'].includes(chord[0])) {
      return this.getRomanQuality(chord);
    }
    var startOfQualityIdx = (chord.includes('#') || chord.includes('b')) ? 2 : 1;
    return chord.slice(startOfQualityIdx);
  }

  static getRomanQuality(chord) {
    for (var i = 0; i < chord.length; i++) {
      if (!['I', 'i', 'V', 'v'].includes(chord[i])) {
        return chord.slice(i);
      }
    }
    return "";
  }

  static isMinor(chord) {
    return chord.includes('m');
  }

  static isSlash(chord) {
    return chord.includes('/');
  }

  static getSlashTop(chord) {
    return chord.split('/')[0];
  }

  static getSlashBottom(chord) {
    return chord.split('/')[1];
  }

  static getPlayKey(actualKey, capo) {
    console.log(`actual key: ${actualKey} capo: ${capo}`);
    return this.transpose(actualKey, 12 - capo);
  }

  static getRomanNumeral(semitones, isMinorChord, isMinorKey) {
    switch (semitones) {
      case 0:
        return isMinorChord ? 'i' : 'I';
      case 1:
        return isMinorChord ? 'bii' : 'bII';
      case 2:
        return isMinorChord ? 'ii' : 'II';
      case 3:
        if (isMinorKey) {
          return isMinorChord ? 'iii' : 'III';
        } else {
          return isMinorChord ? 'biii' : 'bIII';
        }
      case 4:
        if (isMinorKey) {
          return isMinorChord ? '#iii' : '#III';
        } else {
          return isMinorChord ? 'iii' : 'III';
        }
      case 5:
        return isMinorChord ? 'iv' : 'IV';
      case 6:
        return isMinorChord ? 'bv' : 'bV';
      case 7:
        return isMinorChord ? 'v' : 'V';
      case 8:
        if (isMinorKey) {
          return isMinorChord ? 'vi' : 'VI';
        } else {
          return isMinorChord ? 'bvi' : 'bVI';
        }
      case 9:
        if (isMinorKey) {
          return isMinorChord ? '#vi' : '#VI';
        } else {
          return isMinorChord ? 'vi' : 'VI';
        }
      case 10:
        if (isMinorKey) {
          return isMinorChord ? 'vi' : 'VI';
        } else {
          return isMinorChord ? 'bvi' : 'bVI';
        }
      case 11:
        if (isMinorKey) {
          return isMinorChord ? '#vii' : '#VII';
        } else {
          return isMinorChord ? 'vii' : 'VII';
        }

      default:
        return -1;
    }
  }

  static getRomanChord(chord, songKey) {
    if (typeof chord == 'undefined') return chord;
    console.log(`chord: ${chord} songKey: ${songKey}`);
    var romanChord = this.getRomanNumeral(this.semitoneDifference(this.getPitch(songKey), this.getPitch(chord)),
                                          this.isMinor(chord), this.isMinor(songKey));

    if (romanChord == -1) {
      return chord;
    }

    return romanChord;

    var mainChord = this.isSlash(chord) ? this.getSlashTop(chord) : chord;
    if(this.getQuality(mainChord) !== 'm') {
      romanChord += this.getQuality(mainChord);
    }

    if(this.isSlash(chord)) {
      romanChord += '/' + this.getRomanNumeral(this.semitoneDifference(this.getPitch(songKey), this.getPitch(this.getSlashBottom(chord))),
                                               false, this.isMinor(songKey));
    }

    return romanChord;
  }
}

class Key {
  constructor(pitch, isMajor) {
    this.pitch = pitch;
    this.isMajor = isMajor;
  }
}

// keyStringTo(n) {
//   var s = "";
//   for (var i = 0; i < n; i++) {
//     s += ' ';
//   }
//   return s;
// }

class TransposeDropdown extends Component {
  handleTranspose = this.handleTranspose.bind(this);
  handleTranspose(event, data) {
    this.props.onTranspose(data.value)
  }

  getKeyOptions() {
    return Chord.isMinor(this.props.transKey) ? keyOptionsMinor : keyOptions;
  }

  render() {
    return (
      <div style={{ width: '7em', textAlign: 'center' }}>
        <Dropdown
          button
          className='icon'
          fluid
          floating
          labeled
          icon='music'
          options={this.getKeyOptions()}
          search
          placeholder='Transpose'
          onChange={this.handleTranspose}
          value={this.props.transKey}
          // defaultValue={this.props.transKey }
        />
      </div>
    );
  }
}

class CapoDropdown extends Component {
  handleCapoChange = this.handleCapoChange.bind(this);
  handleCapoChange(event, data) {
    this.props.onCapoChange(data.value)
  }

  render() {
    return (
      <div style={{ width: '5em', display: 'flex', justifyContent: 'center'}}>
        <Dropdown
          fluid
          search
          selection
          options={capoOptions}
          placeholder='Capo'
          // defaultValue={this.props.capo}
          value={this.props.capo}
          onChange={this.handleCapoChange}
        />
      </div>
    );
  }
}

class KeyAndCapoSelect extends Component {
  state = {
    transKey: this.props.origKey,
    capo: this.props.capo,
  };

  handleTranspose = this.handleTranspose.bind(this);
  handleTranspose(newTransKey) {
    this.setState({
      transKey: newTransKey,
    }, this.playKeyChanged);
  }

  handleCapoChange = this.handleCapoChange.bind(this);
  handleCapoChange(newCapo) {
    this.setState({
      capo: newCapo,
    }, this.playKeyChanged);
  }

  playKeyChanged() {
    this.props.onTranspose(Chord.getPlayKey(this.state.transKey, this.state.capo))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.songId !== this.props.songId) {
      this.setState({
        transKey: this.props.origKey,
        capo: this.props.capo,
      });
    }
  }

  render() {
    return (
      <div className = 'flex-container-key-capo' >
        <div><p className = 'label-key-capo'>Original</p><p className = 'immutable-key'>{this.props.origKey}</p></div>
        <div><p className = 'label-key-capo'>Transpose</p><TransposeDropdown transKey = {this.state.transKey} onTranspose = {this.handleTranspose} /></div>
        <div><p className = 'label-key-capo'>Capo</p><CapoDropdown capo = {this.state.capo} onCapoChange = {this.handleCapoChange} /></div>
        <div><p className = 'label-key-capo'>Play</p><p className = 'immutable-key'>{Chord.getPlayKey(this.state.transKey, this.state.capo)}</p></div>
      </div>
    );
  }
}

export default KeyAndCapoSelect

export {
  Chord, Pitch
}
