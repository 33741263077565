import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

class ColorThemeDropdown extends Component {
  handleColorThemeChange = this.handleColorThemeChange.bind(this);
  handleColorThemeChange(event, data) {
    this.props.onColorThemeChange(data.value)
  }

  getOptionsFromThemeNames() {
    var themeNameOptions = [];
    for (var i = 0; i < this.props.themeNames.length; i++) {
      themeNameOptions.push({text: this.props.themeNames[i], value: i});
    }
    return themeNameOptions;
  }

  render() {
    return (
      <div style={{ textAlign: 'center'}}>
        <Dropdown
          placeholder='Color Theme'
          search
          selection
          options={this.getOptionsFromThemeNames()}
          onChange={this.handleColorThemeChange} />
      </div>
    );
  }
}

export default ColorThemeDropdown
