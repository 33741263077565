import React, { Component } from 'react';
import firebase from '../../../app/config/firebaseConfig';
import MetadataTable from './MetadataTable'
import SongViewer from '../SongViewer/SongViewer'

const db = firebase.firestore();
// db.settings({
//   timestampsInSnapshots: true
// });

var fileSongs = []
var emptySong = require('../../../data/emptySong.json');

class MetadataBrowser extends Component {
  state = {
    songs: fileSongs,
    currentSong: emptySong
  };

  componentDidMount() {
    db.collection("songs")
    .onSnapshot(function(querySnapshot) {
        var cloudSongs = [];
        querySnapshot.forEach(function(doc) {
          var song = doc.data();
          song['id'] = doc.id;
          cloudSongs.push(song);
        });
        this.setState({ songs: cloudSongs });
    }.bind(this));
  }

  handleSongChange = this.handleSongChange.bind(this);

  handleSongChange(song) {
    for (let each of this.state.songs) {
      if (each.metadata.title === song) {
        console.log(`change to ${each.metadata.title}`)
        this.setState({
          currentSong: each
        });
      }
    }
  }

  render() {
    return (
      <div style={{ height: '100%' }} >
        <MetadataTable
          songs = {this.state.songs}
          onSongChange = {this.handleSongChange} />

        <SongViewer
          song = {this.state.currentSong} />
      </div>
    );
  }
}

export default MetadataBrowser;

