import React, { Component } from 'react'

import KeyAndCapoSelect, {Chord, Pitch} from './TransposeDropdown'
import ColorThemeDropdown from './ColorThemeDropdown'
import XRayButton from './XRayButton'

import './SongViewer.css';

class SongHeader extends Component {
  handleTranspose = this.handleTranspose.bind(this);
  handleTranspose(newPlayKey) {
    this.props.onTranspose(newPlayKey)
  }

  render() {
    return (
      <div className="metadata">
        {this.props.metadata.title      ? <h1 className="title" style={{ color: '#'+this.props.theme.bright1 }}>{this.props.metadata.title}</h1>  : null}
        {this.props.metadata.artist     ? <h2 style={{ color: '#'+this.props.theme.bright2 }}>{this.props.metadata.artist}</h2> : null}
        {this.props.metadata.album      ? <h2 style={{ color: '#'+this.props.theme.dark }}>{this.props.metadata.album}</h2>  : null}
        {this.props.metadata.year       ? <h2 style={{ color: '#'+this.props.theme.dark }}>{this.props.metadata.year}</h2>   : null}
        {/* {this.props.metadata.key        ? <h2 style={{ color: '#'+this.props.theme.main }}>{this.props.metadata.key} {this.props.metadata.capo && this.props.metadata.capo > 0 ? '(capo ' + this.props.metadata.capo + ')' : ''}</h2> : null} */}
        {this.props.metadata.key        ? <KeyAndCapoSelect
                                            songId = {this.props.songId}
                                            origKey = {this.props.metadata.key}
                                            capo = {parseInt(this.props.metadata.capo)}
                                            onTranspose = {this.handleTranspose} /> : null}
        {this.props.metadata.spotifyid  ? <iframe className="spotify" src={`https://open.spotify.com/embed/track/${this.props.metadata.spotifyid}`} title={`${this.props.metadata.spotifyid}`} width="300" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe> : null}
      </div>
    );
  }
}

class LyricLine extends Component {
  render() {
    return (
      <div>
        <p className="lyric" style={{ color: '#'+this.props.theme.bright1 }}>{this.props.lyrics}</p>
      </div>
    );
  }
}

class ChordLine extends Component {
  makeSpaces(n) {
    var s = "";
    for (var i = 0; i < n; i++) {
      s += ' ';
    }
    return s;
  }

  addChord(s, chord) {
    return s.slice(0, chord.position) + chord.chord + s.slice(chord.position);
  }

  getPitchAndQuality(chord) {
    var pitch = chord.length > 1 && (chord[1] == 'b' || chord[1] == '#') ?
      chord.slice(0, 2) : chord[0];
    var quality = chord.slice(pitch.length);
    quality = quality.replace('M7', 'Δ');
    return {
      'pitch': pitch,
      'quality': quality
    }
  }

  makeLine(chords) {
    var line = [];
    var curr = 0;
    for (var i = 0; i < chords.length; i++) {
      var spaces = this.makeSpaces(chords[i].position - curr);
      line.push(spaces);
      // var chordStruct = this.getPitchAndQuality(chords[i].chord);
      var pitch = chords[i].chord.pitch;
      var quality = chords[i].chord.quality;
      if (this.props.isXRay) {
        if (quality === 'm') {
          quality = '';
        }
      }
      console.log(`pitch: ${pitch} quality: ${quality} pos: ${chords[i].position} curr: ${curr} spaces: ${spaces.length}`)
      var bass = chords[i].chord.bass;
      var slash = `/${bass}`;
      quality = quality ? quality.replace('M7', 'Δ') : '';
      line.push(pitch);
      line.push(quality == 'm' ? 'm' : <sup style={{fontSize: 'medium'}}>{quality}</sup>);
      if (bass) line.push(slash);
      curr += spaces.length + (pitch ? pitch.length : 0) + (quality ? quality.length : 0) + (bass ? slash.length : 0);
      console.log(`pitch: ${pitch} quality: ${quality} pos: ${chords[i].position} curr: ${curr} spaces: ${spaces.length}`)
    }
    return line;
  }

  // makeLine(chords) {
  //   var s = this.makeSpaces(100);
  //   for (var i = 0; i < chords.length; i++) {
  //     s = this.addChord(s, chords[i]);
  //   }
  //   return s;
  // }

  render() {
    return (
      <div>
        <p className="chord" style={{ color: '#'+this.props.theme.bright3, marginTop: '1em' }} >{this.makeLine(this.props.chords)}</p>
      </div>
    );
  }
}

class SongLine extends Component {
  render() {
    return (
      <div>
        {this.props.chords.length > 0 ? <ChordLine chords={this.props.chords} isXRay = {this.props.isXRay} theme = {this.props.theme} /> : null}
        {this.props.lyrics.length > 0 ? <LyricLine lyrics={this.props.lyrics} theme = {this.props.theme} /> : null}
      </div>
    );
  }
}

class SongStanza extends Component {
  makeLines(lines) {
    var songLines = [];
    for (var i = 0; i < lines.length; i++) {
      songLines.push(<SongLine
        key = {i}
        isXRay = {this.props.isXRay}
        lyrics = {lines[i].lyrics}
        chords = {lines[i].chords}
        theme = {this.props.theme} />);
    }
    return songLines;
  }

  render() {
    return (
      <div>
        {this.makeLines(this.props.lines)}
        <p>{"\n"}</p>
      </div>
    );
  }
}

class SongPart extends Component {
  makeStanzas(stanzas) {
    var songStanzas = [];
    for (var i = 0; i < stanzas.length; i++) {
      songStanzas.push(<SongStanza
        key = {i}
        isXRay = {this.props.isXRay}
        lines = {stanzas[i].lines}
        theme = {this.props.theme} />);
    }
    return songStanzas;
  }

  render() {
    return (
      <div>
        <h4 className="partLabel" style={{ color: '#'+this.props.theme.muted }}>{this.props.name}</h4>
        {this.makeStanzas(this.props.stanzas)}
      </div>
    );
  }
}

class SongBody extends Component {
  makeParts(parts) {
    var songParts = [];
    for (var i = 0; i < parts.length; i++) {
      songParts.push(<br key = {100000+i}/>);
      songParts.push(<SongPart
        key = {i}
        name = {parts[i].name}
        isXRay = {this.props.isXRay}
        stanzas = {parts[i].stanzas}
        theme = {this.props.theme} />);
    }
    songParts.push(<br key = {-1}/>);
    return songParts;
  }

  render() {
    return (
      <div>
        {this.makeParts(this.props.parts)}
      </div>
    );
  }
}

var themes_file = require('./themes.json');

class SongViewer extends Component {
  state = {
    playKey: this.props.song.metadata.key,
    isXRay: false,
    themes: themes_file,
    currentThemeIdx: 0,
    currentThemeVariation: "contrast"
  };

  // transposeAndXRay = this.transposeAndXRay.bind(this);
  handleTranspose = this.handleTranspose.bind(this);
  handleXRayChange = this.handleXRayChange.bind(this);
  handleThemeChange = this.handleThemeChange.bind(this);

  componentDidUpdate(prevProps) {
    console.log(`prev id: ${prevProps.song.id} id: ${this.props.song.id}`)
    console.log(`meta: ${this.props.song.metadata.artist}`)
    if (prevProps.song.id !== this.props.song.id) {
      console.log(`here i am in component did update`);
      this.setState({
        playKey: Chord.getPlayKey(this.props.song.metadata.key, parseInt(this.props.song.metadata.capo)),
      });
    }
  }

  transposeAndXRay() {
    if(!this.isTranspose() && !this.state.isXRay) {
      return this.props.song.body;
    }

    var semitonesOffset = Chord.semitoneDifference(this.props.song.metadata.key, this.state.playKey);
    console.log(`semitonesOffset: ${semitonesOffset} song key: ${this.props.song.metadata.key} play key: ${this.state.playKey}`);

    var newBody = JSON.parse(JSON.stringify(this.props.song.body));

    for (var i = 0; i < this.props.song.body.length; i++) {
      for (var j = 0; j < this.props.song.body[i].stanzas.length; j++) {
        for (var k = 0; k < this.props.song.body[i].stanzas[j].lines.length; k++) {
          for (var m = 0; m < this.props.song.body[i].stanzas[j].lines[k].chords.length; m++) {
            var pitch = this.props.song.body[i].stanzas[j].lines[k].chords[m].chord.pitch;
            console.log(`pitch: ${pitch}`);
            var bass = this.props.song.body[i].stanzas[j].lines[k].chords[m].chord.bass;
            var quality = this.props.song.body[i].stanzas[j].lines[k].chords[m].chord.quality;
            if (this.isTranspose()) {
              pitch = Pitch.transpose(pitch, semitonesOffset);
              console.log(`pitch: ${pitch}`);
              bass = Pitch.transpose(bass, semitonesOffset);
            }
            if (this.state.isXRay) {
              pitch = Chord.getRomanChord(pitch+quality, this.state.playKey);
              bass = Chord.getRomanChord(bass, this.state.playKey);
            }
            newBody[i].stanzas[j].lines[k].chords[m].chord.pitch = pitch;
            newBody[i].stanzas[j].lines[k].chords[m].chord.bass = bass;
          }
        }
      }
    }

    return newBody;
  }

  handleTranspose(newPlayKey) {
    this.setState({
      playKey: newPlayKey,
    });
  }

  isTranspose() {
    return this.state.playKey !== this.props.song.metadata.key
  }

  handleXRayChange() {
    this.setState((state) => {
      return {isXRay: !state.isXRay}
    });
  }

  handleThemeChange(newIndex) {
    this.setState({
      currentThemeIdx: newIndex,
    });
  }

  getSongBody() {
    return this.transposeAndXRay();
  }

  getTheme() {
    var theme = this.state.themes[this.state.currentThemeIdx];
    if (this.state.currentThemeVariation == "standard") {
      return theme.standard;
    } else if (this.state.currentThemeVariation == "contrast") {
      return theme.contrast;
    } else {
      return theme.light;
    }
  }

  getThemeList() {
    var themeNameList = [];
    for (const th of this.state.themes) {
      themeNameList.push(th['name']);
    }
    return themeNameList;
  }

  render() {
    return (
      <div style={{ backgroundColor: '#'+this.getTheme().background, minHeight: '100%' }}>
        <div className = 'toolbar'>
          <XRayButton active = {this.state.isXRay}
                      onToggle = {this.handleXRayChange} />
          <ColorThemeDropdown themeNames = {this.getThemeList()}
                              onColorThemeChange = {this.handleThemeChange} />
        </div>
        <div style={{ margin: '2em' }}>
          <SongHeader metadata = {this.props.song.metadata}
                      songId = {this.props.song.id}
                      onTranspose = {this.handleTranspose}
                      theme = {this.getTheme()} />
          <SongBody parts = {this.getSongBody()}
                    isXRay = {this.state.isXRay}
                    theme = {this.getTheme()} />
        </div>
      </div>
    );
  }
}

export default SongViewer;
