import React, { Component } from 'react';
import { Table } from 'semantic-ui-react'

class MetadataColumn extends Component {
  handleFilterChange = this.handleFilterChange.bind(this);

  handleFilterChange(item) {
    if(this.props.onFilterChange) {
      this.props.onFilterChange(item);
    }
  }

  render() {
    return (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='headerCell' style={{background: "#CCC", color: "#444"}}>{this.props.header}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.hasAllOption ?
              <Table.Row>
                <Table.Cell
                  selectable
                  active = {this.props.filter === ''}
                  onClick={() => this.handleFilterChange('')} >
                  <a>All</a>
                </Table.Cell>
              </Table.Row> : '' }

            {this.props.items.map(item => (
              <Table.Row>
                <Table.Cell
                  selectable
                  active = {item === this.props.filter}
                  onClick={() => this.handleFilterChange(item)} >
                  <a>{item}</a>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default MetadataColumn;
