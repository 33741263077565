import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCly7aczDuCs6b0O3OiZM09pZt7BQXRl8I",
  authDomain: "shaladida-app.firebaseapp.com",
  databaseURL: "https://shaladida-app.firebaseio.com",
  projectId: "shaladida-app",
  storageBucket: "shaladida-app.appspot.com",
  messagingSenderId: "148828122921"
};
firebase.initializeApp(firebaseConfig);

export default firebase;
