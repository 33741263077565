import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react'

class XRayButton extends Component {
  render() {
    return (
      <div style = {{width: '3em', height: '3em', }}>
        <Button icon
                toggle
                fluid
                active={this.props.active}
                onClick={this.props.onToggle}>
          <Icon name='eye' />
        </Button>
      </div>
    )
  }
}

export default XRayButton