import React, { Component } from 'react';
import MetadataColumn from './MetadataColumn'
import './MetadataTable.css';

class MetadataTable extends Component {
  state = {
    keyFilter: '',
    artistFilter: '',
    songSelect: ''
  };

  handleKeyFilterChange = this.handleKeyFilterChange.bind(this);
  handleArtistFilterChange = this.handleArtistFilterChange.bind(this);
  handleSongChange = this.handleSongChange.bind(this);

  handleKeyFilterChange(key) {
    // console.log(`Key filter: ${key}`);
    this.setState({
      keyFilter: key,
      artistFilter: ''
    });
  }

  handleArtistFilterChange(artist) {
    // console.log(`Artist filter: ${artist}`);
    this.setState({
      artistFilter: artist
    });
  }

  handleSongChange(song) {
    // console.log(`Song select: ${song}`);
    this.setState({
      songSelect: song
    });
    this.props.onSongChange(song)
  }

  render() {
    return (
      <div style={{ display: "flex" }}>
        <div id="keyColumn" className="column">
        <MetadataColumn
          header = "Key"
          items = {Array.from(new Set(this.props.songs.map(song => song.metadata.key))).sort()}
          filter = {this.state.keyFilter}
          onFilterChange = {this.handleKeyFilterChange}
          hasAllOption = {true} />
        </div>

        <div id="artistColumn" className="column">
        <MetadataColumn
          header = "Artist"
          items = {Array.from(new Set(this.props.songs.filter(song =>
            !this.state.keyFilter || song.metadata.key === this.state.keyFilter
          ).map(song => song.metadata.artist))).sort()}
          filter = {this.state.artistFilter}
          onFilterChange = {this.handleArtistFilterChange}
          hasAllOption = {true} />
        </div>

        <div id="songColumn" className="column">
        <MetadataColumn
          header =  "Song"
          items = {this.props.songs.filter(song =>
            song.metadata.artist.includes(this.state.artistFilter) &&
            (!this.state.keyFilter || song.metadata.key === this.state.keyFilter)
          ).map(song => song.metadata.title).sort()}
          filter = {this.state.songSelect}
          onFilterChange = {this.handleSongChange}
          hasAllOption = {false}/>
        </div>

      </div>
    );
  }
}

export default MetadataTable;
